var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dialog && _vm.user)?_c('div',[_c('v-dialog',{attrs:{"value":_vm.dialog,"persistent":"","transition":"dialog-bottom-transition","max-width":"50%"}},[_c('v-card',[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},[_c('span',{attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},[_vm._v("×")])])]),_c('Loading',{attrs:{"visible":_vm.loading}}),_c('v-card-text',[_c('v-container',[_c('v-tabs',{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[(item == 'Withdraw History')?_c('span',[_vm._v("Cash Out History")]):_c('span',[_vm._v("Cash In History")])])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"table table-striped",attrs:{"footer-props":_vm.footerProps,"items-per-page":_vm.data.rowsPerPage,"page":_vm.data.page,"server-items-length":item === 'Withdraw History'
                        ? _vm.withdrawlist.objectCount
                        : _vm.depositlist.objectCount,"headers":_vm.headers,"items":item === 'Withdraw History'
                        ? _vm.withdrawlist.object
                        : _vm.depositlist.object,"mobile-breakpoint":"0"},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},_vm.getData],"update:page":[function($event){return _vm.$set(_vm.data, "page", $event)},_vm.getData]},scopedSlots:_vm._u([{key:`item.agent_username`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.agent_username)+" ")])]}},{key:`item.amount`,fn:function({ item }){return [(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          (item.currency_type === 1 ||
                            item.currency_type === 3)
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.amount))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 2
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.amount))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 4
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.amount))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 5
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.amount))+" ")]):_vm._e()]}},{key:`item.old_balance`,fn:function({ item }){return [(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          (item.currency_type === 1 ||
                            item.currency_type === 3)
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.old_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 2
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.old_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 4
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.old_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 5
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.old_balance))+" ")]):_vm._e()]}},{key:`item.new_balance`,fn:function({ item }){return [(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          (item.currency_type === 1 ||
                            item.currency_type === 3)
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.new_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 2
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.new_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 4
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.new_balance))+" ")]):_vm._e(),(
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 5
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.new_balance))+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)}),1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }